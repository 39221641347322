'use client';

import React, { useEffect } from 'react';
import Error from '@ui/pages/Error/Error';
import useDictionary from '@utils/hooks/useDictionary';

export default function Page({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const dictionary = useDictionary({
    keys: [
      'backToHomePage',
      'somethingWentWrong',
      'pageNotFoundQuoteText',
      'pageNotFoundQuoteAuthor',
    ],
  });
  useEffect(() => {
    console.error(error);
  }, [error]);

  return <Error dictionary={dictionary} />;
}
